import React, { Fragment, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../style.css";
import Modal from "./Modal";
import {
  faTrash,
  faHeart as faHeartSolid,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  deleteDoc,
  doc,
  Firestore,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import Alertas from "./Alertas";
import { Spinner } from "react-bootstrap";
import { compare } from "bcryptjs-react";

interface CartaRecetaProps {
  db: Firestore;
  id: string;
  imagenUrl: string;
  nombre: string;
  ingredientes: string[];
  categoria: string;
  apuntes?: string;
  isGridView?: boolean;
  isEditing?: boolean;
  isFavorite: boolean;
  onDeleteReceta: (id: string) => void;
  onFavoriteToggle: (id: string) => void;
}

const CartaReceta: React.FC<CartaRecetaProps> = ({
  db,
  id,
  imagenUrl,
  nombre,
  ingredientes,
  categoria,
  apuntes,
  isGridView = false,
  isEditing = false,
  isFavorite: initialFavorite,
  onDeleteReceta,
  onFavoriteToggle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState<
    "success" | "error" | "warning" | "info"
  >("error");
  const [loading, setLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(initialFavorite);
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    // Comprueba si la receta ya está marcada como favorita
    const fetchFavoriteStatus = async () => {
      const docRef = doc(db, "recetas", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsFavorite(docSnap.data().isFavorite || false);
      }
    };

    fetchFavoriteStatus();
  }, [db, id]);

  const handleShowModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDelete = async (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setIsConfirmModalOpen(true);
  };

  const cancelDeleteReceta = () => {
    setIsConfirmModalOpen(false);
  };

  const confirmDeleteReceta = async () => {
    if (id) {
      setLoading(true);
      try {
        await deleteDoc(doc(db, "recetas", id));
        onDeleteReceta(id);
        setAlertVariant("success");
        setAlertMessage(`La receta "${nombre}" ha sido eliminada.`);
        setAlertOpen(true);
      } catch (error) {
        console.error("Error al eliminar la receta:", error);
        setAlertOpen(true);
        setAlertVariant("error");
        setAlertMessage("Error al eliminar la receta.");
      } finally {
        setIsConfirmModalOpen(false);
        setLoading(false);
      }
    }
  };

  const toggleFavorite = async (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();

    const hasAccess = sessionStorage.getItem("hasAccess");
    if (!hasAccess) {
      setIsModalPasswordOpen(true);
      return;
    }
    
    setLoading(true);
    try {
      const updatedFavorite = !isFavorite;
      await updateDoc(doc(db, "recetas", id), { isFavorite: updatedFavorite });

      setIsFavorite(updatedFavorite);

      // En lugar de recargar toda la lista, actualizamos el estado local desde Recetas
      onFavoriteToggle(id);

      setAlertVariant("success");
      setAlertMessage(
        updatedFavorite
          ? `Receta "${nombre}" añadida a favoritos.`
          : `Receta "${nombre}" eliminada de favoritos.`
      );
      setAlertOpen(true);
    } catch (error) {
      console.error("Error al actualizar la receta favorita:", error);
      setAlertVariant("error");
      setAlertMessage("Error al actualizar la receta favorita.");
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const verificarContraseña = async () => {
    const docRef = doc(db, "config", "passwordDoc");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const storedPassword = docSnap.data().password;
      if (!password) {
        setAlertOpen(true);
        setAlertVariant("info");
        setAlertMessage("Por favor, introduce una contraseña.");
        return;
      }

      setLoading(true);
      const isMatch = await compare(password, storedPassword);
      if (isMatch) {
        sessionStorage.setItem("hasAccess", "true");
        setIsModalPasswordOpen(false);
            
        setPassword("");
        setAlertOpen(true);
        setAlertVariant("success");
        setAlertMessage("Contraseña correcta.");
      } else {
        setAlertOpen(true);
        setAlertVariant("error");
        setAlertMessage(
          "Contraseña incorrecta. Por favor, inténtalo de nuevo."
        );
      }
    } else {
      console.error("No se encontró el documento de contraseña.");
      setAlertOpen(true);
      setAlertVariant("error");
      setAlertMessage("No se encontró el documento de contraseña.");
    }
    setLoading(false);
  };

  return (
    <Fragment>
      {loading && (
        <div className="spinner-overlay">
          <Spinner className="custom-spinner" animation="border" />
        </div>
      )}
      <div
        className="card mb-4"
        style={{ width: "100%" }}
        onClick={handleShowModal}
      >
        <div className="image-container">
          <img
            src={imagenUrl}
            className="card-img-top fixed-image"
            alt={nombre}
            style={{
              width: "100%",
              height: isGridView ? "180px" : "250px",
              overflow: "hidden",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center">{nombre}</h5>
          {!isGridView && (
            <div className="row">
              <div className="col">
                <p className="card-text">
                  <strong>Categoría:</strong> {categoria}
                </p>
              </div>
              <div className="col-4 text-end mt-2">
                {isEditing && (
                  <FontAwesomeIcon
                    title="Eliminar"
                    icon={faTrash}
                    onClick={(e) => handleDelete(e)}
                    style={{
                      fontSize: "1.2rem",
                      verticalAlign: "middle",
                      color: "#6c757d",
                      marginRight: "10px",
                    }}
                  />
                )}
                <FontAwesomeIcon
                  title={
                    isFavorite ? "Quitar de favoritos" : "Añadir a favoritos"
                  }
                  icon={isFavorite ? faHeartSolid : faHeartRegular}
                  onClick={(e) => toggleFavorite(e)}
                  style={{
                    fontSize: "1.2rem",
                    verticalAlign: "middle",
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isConfirmModalOpen && (
        <Modal onClose={cancelDeleteReceta}>
          <div className="modal-body">
            <h2>Confirmar Eliminación</h2>
            <p>¿Estás seguro de que deseas eliminar esta receta? </p>
            <p>Esta acción no se puede deshacer.</p>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-secondary btn-block"
                  onClick={cancelDeleteReceta}
                >
                  Cancelar
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-danger btn-block"
                  onClick={confirmDeleteReceta}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <div className="modal-body" style={{ padding: 0 }}>
            <div className="image-container">
              <img
                src={imagenUrl}
                className="img-fluid fixed-image"
                alt={nombre}
              />
            </div>
            <h3 className="text-center pt-3 mb-1">
              <strong>{nombre}</strong>
            </h3>
            <p className="text-center">{categoria}</p>
            <div className={`${apuntes ? "mb-4" : ""}`}>
              <p className="mb-2">
                <strong>Ingredientes:</strong>
              </p>
              <div className="row">
                {ingredientes.map((ingrediente, index) => (
                  <div className="col-6" key={index}>
                    <li className="ps-2">{ingrediente}</li>
                  </div>
                ))}
              </div>
            </div>
            {apuntes && (
              <>
                <p className="mb-2">
                  <strong>Notas:</strong>
                </p>
                <p>{apuntes}</p>
              </>
            )}
          </div>
        </Modal>
      )}

      {isModalPasswordOpen && (
        <Modal onClose={() => setIsModalPasswordOpen(false)}>
          <div className="modal-body">
            <h2>Introduce la contraseña</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                verificarContraseña();
              }}
            >
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="botonPrimarioRecetas" type="submit">
                Entrar
              </button>
            </form>
          </div>
        </Modal>
      )}

      <Alertas
        message={alertMessage}
        variant={alertVariant}
        duration={3000}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      />
    </Fragment>
  );
};

export default CartaReceta;
